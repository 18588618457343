import * as React from 'react';
import { ISectionProps } from '../../Section/Section.types';
import Section from '../../Section/viewer/Section';

const RefComponent: React.FC<ISectionProps> = props => {
  const tagName = props.tagName || ('div' as keyof JSX.IntrinsicElements);
  return <Section {...props} tagName={tagName} />;
};

export default RefComponent;
