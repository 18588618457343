import { DropDownMenuSkin } from './DropDownMenu.types';

export const ARIA_LABEL_NAMESPACE = 'ariaLabels';
export const ARIA_LABEL_KEY = 'dropDownMenu_AriaLabel_TopLevel_SiteNavigation';
export const ARIA_LABEL_DEFAULT = 'Site';
export const SKINS_WITH_UTILITY_SET = new Set<DropDownMenuSkin>([
  'PointerMenuButtonHorizontalMenuAdaptationSkin',
  'PointerMenuButtonSkin',
  'VerticalRibbonsMenuButtonSkin',
  'RibbonsMenuButtonSkin',
]);

export const DATA_ATTRS = {
  dropdownShown: 'data-dropdown-shown',
};

export const MORE_BUTTON_INDEX = '__more__';
